import { apiGrelist, apiGreDetail, apiDelCollect, apiCollect, apiCheckOk, apiGetTeacherDetail } from '@/api/api.js'
export default {
    data() {
        return {
            title: "",
            greDropList: false,
            greChooseId: null,
            loading: true,
            emptyView: false,//无数据
            checkOkButton: false,

            // 机经内容
            greDetailData: null,
            greMemory: " Trembling with cold and hunger crept she on, the picture of sorrow: poor little child.The snow- flakes fell on her long, fair hair, which curled in such pretty ringlets over her shoulders but she thought not of her own beauty, or of the cold.Lights were glimmering through every window, and the savor of roast goose reached her from several houses it was New Year’s eve, and it was of this that she thought. ",

            // 参数
            questionListParamsBase: {
                classify_pid: sessionStorage.getItem('clid'),
                page: 1,
                type: 'new',
            },
            // 当前选中题目前后id
            listIdx: null,
            titleIdx: null,
            prevId: null,
            nextId: null,

            // 下拉菜单
            fristLoad: true,
            tabOn: '1',
            dropTitle: "最新",
            tabData: [
                {
                    type: 'new',
                    id: '1',
                    page: 1,
                    list: [],
                    finish: false,
                },
                {
                    type: 'hot',
                    id: '2',
                    page: 1,
                    list: [],
                    finish: false,
                },
                {
                    type: 'unread',
                    id: '3',
                    page: 1,
                    list: [],
                    finish: false,
                },

                // {
                //     type: 'all',
                //     id: '4',
                //     page: 1,
                //     list: [],
                //     finish: false,
                // }
            ],
            hasDetail: false,
            teacherDetail: {},//老师详解
            dialogTeacherDetail: false,
            isScoll:true
        }
    },
    mounted() {
        this.loadQuestionList();
    },
    methods: {
        // 滚动加载
        loadQuestionList() {
            let paramsBase = this.questionListParamsBase;
             if(!this.isScoll){
                return;
            }
            this.isScoll = false;
            apiGrelist(paramsBase).then((res) => {
                // 判断是否到最后一页
				console.log(res)
                if (res.data.length < 0) {
                    this.tabData[this.tabOn - 1].finish = true;
                } else {
                    // 加载数据
                    this.tabData[this.tabOn - 1].list.push.apply(this.tabData[this.tabOn - 1].list, res.data);
                    this.tabData[this.tabOn - 1].page += 1;
                    this.questionListParamsBase.page = this.tabData[this.tabOn - 1].page;
                     this.isScoll = true;
                }
                // 判断是否有数据
                if (this.fristLoad && res.data.length == 0) {
                    this.emptyView = true
                    return
                } else {
                    this.emptyView = false
                }
                // 是否初次加载，默认加载第一题
                if (this.fristLoad) {
                    // 初始化第一个题目
                    this.greChooseId = this.tabData[0].list[0].id;
                    this.getGreDetail();
                    this.getAdjoinId(0, 0);
                    this.fristLoad = false;
                }
                // 判断数据来源
                if (this.$route.query.id) {
                    this.greChooseId = this.$route.query.id
                    this.getGreDetail()
                }
            })
        },
        // 点击tab
        handleTab(e) {
            // 判断是否要加载
            this.isScoll = true;
            if (this.tabData[e.index].finish == true) {
                return
            }
            // 设置参数
            console.log(e.index)
            this.dropTitle = e.label;
            this.questionListParamsBase.type = this.tabData[parseInt(e.index)].type;
            this.questionListParamsBase.page = this.tabData[e.index].page;
            this.loadQuestionList();
        },

        // 展开收起菜单
        handleGreDropList() {
            if (this.greDropList == true) {
                this.greDropList = false
            } else {
                this.greDropList = true
            }
        },
        // 点击菜单内容
        handleGetQuestion(id, listIdx, titleIdx) {
            this.getAdjoinId(listIdx, titleIdx)
            this.questionListParamsBase.type = this.tabData[listIdx].type;
            this.loading = true;
            this.greChooseId = id;
            this.getGreDetail();
            this.greDropList = false;

        },
        // 获取上下题目id
        getAdjoinId(listIdx, titleIdx) {
            this.listIdx = listIdx;
            this.titleIdx = titleIdx;
            // 判断是否第一题
            if (titleIdx == 0) {
                this.prevId = null;
            } else {
                this.prevId = this.tabData[listIdx].list[titleIdx - 1].id
            }
            // 判断是否为最后一题
            if (titleIdx == this.tabData[listIdx].list.length - 1) {
                this.nextId = null;
            } else {
                this.nextId = this.tabData[listIdx].list[titleIdx + 1].id;
            }
        },

        // 获取机经详情
        getGreDetail() {
			console.log(this.greChooseId)
            let paramsBase = {
                greid: this.greChooseId
            }
            apiGreDetail(paramsBase).then((res) => {
                console.log(res.data)
                this.greDetailData = res.data;
                this.title = res.data.abbr;
                this.hasDetail = res.data.hasDetail;
                if (res.data.check == 0) {
                    this.checkOkButton = false;
                } else {
                    this.checkOkButton = true;
                }
                this.loading = false;
            })
        },

        // 下一个机经
        nextGre() {
            this.loading = true;
            this.getAdjoinId(this.listIdx, this.titleIdx)
            if (this.nextId != null) {
                this.greChooseId = this.nextId;
                this.getGreDetail()
                this.titleIdx = this.titleIdx + 1
                this.getAdjoinId(this.listIdx, this.titleIdx)
            } else {
                this.$layer.msg('没有下一个机经了')
                this.loading = false;
            }
        },

        // 上一个机经
        prevGre() {
            this.loading = true;
            this.getAdjoinId(this.listIdx, this.titleIdx)
            if (this.prevId != null) {
                this.greChooseId = this.prevId;
                this.getGreDetail()
                this.titleIdx = this.titleIdx - 1
                this.getAdjoinId(this.listIdx, this.titleIdx)
            } else {
                this.$layer.msg('没有上一个机经了')
                this.loading = false;
            }
        },
        // 收藏
        collectGre() {
            let paramsBase = {
                aid: this.greChooseId,
                paramerid: this.greChooseId,
                type: 'gremat'
            }
            if (this.greDetailData.collect_status == 1) {
                apiDelCollect(paramsBase).then((res) => {
                    this.$layer.msg('已取消收藏')
                    this.greDetailData.collect_status = 0;
                })
            } else {
                apiCollect(paramsBase).then((res) => {
                    this.$layer.msg('收藏成功')
                    this.greDetailData.collect_status = 1;
                })
            }
        },
        // 确认考过
        checkOk() {
            let paramsBase = {
                greid: this.greChooseId
            }
            apiCheckOk(paramsBase).then((res) => {
                this.checkOkButton = true;
                this.$layer.msg('确认考过成功')
            })
        },
        hideGreTab() {
            this.greDropList = false
        },
        // 老师详解
        getTeacherDetail() {
            if (this.emptyView) {
                return;
            }
            this.teacherDetail = {
                teacherName: null,
                teacherImg: null,
                text: null,
                video: null,
                audio: {
                    url: null
                }
            }
            let paramsBase = {
                ggid: this.greChooseId
            }
            apiGetTeacherDetail(paramsBase).then((res) => {
                if (res.data == null) {
                    this.$layer.msg(res.msg)
                } else {
                    this.teacherDetail.teacherName = res.data.name;
                    this.teacherDetail.teacherImg = res.data.img_url;
                    for (let i = 0; i < res.data.detail.length; i++) {
                        let type = res.data.detail[i].type;
                        if (type == 'content') {
                            this.teacherDetail.text = res.data.detail[i].content.text;
                        }
                        if (type == 'audio') {
                            this.teacherDetail.audio.url = res.data.detail[i].content;
                        }
                        if (type == 'video') {
                            this.teacherDetail.video = res.data.detail[i].content;
                        }
                    }
                    this.dialogTeacherDetail = true;
                }
            })
        }
    },
}